@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&family=Work+Sans&display=swap);
.styles_container__9XZZk{width:100%;background:#f2f2f2;display:flex;justify-content:flex-start;flex-direction:column;flex:1 0;color:#6b6b6b;font-size:16px}header{display:flex;justify-content:space-between;height:100px}.styles_logo__2HxU2 img{width:200px}h4{color:black}.styles_link__3cUqf{width:100px;padding:10px;font-size:18px;background:#46aba0;color:white;border-radius:4px;height:38px;margin-bottom:5px}.styles_link__3cUqf:hover{cursor:pointer;background:#7dc4bc}.styles_logout__1gq4D{margin-top:5px;display:flex;justify-content:center;align-items:center;width:100px;padding:10px;font-size:18px;background:#46aba0;color:white;border-radius:4px;height:38px}.styles_logout__1gq4D:hover{cursor:pointer;background:#7dc4bc}.styles_divCont__1UCRz{margin-top:5px}.styles_divCont_div__1F04b{margin:5px;padding:5px}.styles_table__1xtF2{box-sizing:border-box;border:2px solid #6b6b6b;table-layout:fixed;border-collapse:collapse}th{padding:20px;background:#d9d9d9}.styles_cell__jeegD,td{width:150px;max-width:150px;min-width:70px;padding:10px;white-space:nowrap;text-align:center;border-collapse:collapse;text-overflow:ellipsis;overflow:hidden}.styles_head__1v2qx .styles_row__2A_Gv .styles_cell__jeegD{background:var(--table-head-color);font-size:16px;font-weight:500}.styles_body__1gfls .styles_row__2A_Gv .styles_cell__jeegD{font-size:14px}

.styles_container__3AKxu{width:100%;height:100vh;background:#f2f2f2;display:flex;justify-content:center}form{margin-top:20%;display:flex;flex-direction:column}form button{margin-top:20px;border-radius:4px;background:#46aba0;padding:20px;font-size:20px;text-transform:uppercase;display:flex;justify-content:center;align-items:center}form button:hover{cursor:pointer;background:#7dc4bc}.styles_input__2tPsr input{font-size:26px}

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;800&display=swap"); */

/* @font-face {
  font-family: "HelveticaNeue_Bd";
  src: url("./assets/fonts/HelveticaNeuBold.ttf");
}
@font-face {
  font-family: "HelveticaNeue_Md";
  src: url("./assets/fonts/HelveticaNeueMedium.ttf");
}
@font-face {
  font-family: "HelveticaNeue_Lt";
  src: url("./assets/fonts/HelveticaNeueLight.ttf");
} */

/* ==START varibles */
:root {
  --main-font: "Helvetica", sans-serif;
}
/* ==END varibles */
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.opened {
  opacity: 1!important;
}

canvas {
  outline: none!important
}

body {
  margin: 0;
  font-family: 'Source Serif Pro', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.image_hotspot {
  /* top: -30px; */
  /* left: -20px; */
  position: relative;
  cursor: pointer;
  max-width: 100%;
}

.image_product {
  top: -10px;
  /* left: 13px; */
  position: relative;
  cursor: pointer;
  max-width: 100%;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 450px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* left: 50%; */
  left: -365%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  flex-direction: row;
}

.image_preview {
  flex: auto;
  width: 40%;
}
.image_preview .image {
  width: 100%;
}

.product_details {
  /* width: 300px; */
  font-size: 20px;
  padding: 20px;
  text-align: left;
}

.product_title a {
  color: #008881;
  font-weight: bold;
  text-decoration: none;
}
.product_title a:hover {
  color: #004d49;
  font-weight: bold;
  text-decoration: none;
}

.product_descr {
  font-size: 15px;
  padding-top: 10px;
}
.product_price {
  font-size: 22px;
  font-weight: bold;
  color: #00bdb4;
  padding-top: 10px;
}


.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(255, 255, 255) transparent transparent transparent;
}

.tooltip:hover {
  z-index: 10000;
}

.tooltip.opened .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltip_content {
  visibility: hidden;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
}

button {
  background: transparent;
  border-radius: 0;
  border: 0;
  color: #f9f9f9;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-out;
  text-decoration: none;
}
/* 
#portal {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f00;
  z-index: 99999999999;
} */

