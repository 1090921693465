.container {
  width: 100%;
  height: 100vh;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
}

form {
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  & button {
    margin-top: 20px;
    border-radius: 4px;
    background: #46aba0;
    padding: 20px;
    font-size: 20px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & button:hover {
    cursor: pointer;
    background: #7dc4bc;
  }
}

.input input {
  font-size: 26px;
 
}


